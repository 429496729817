import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';
import { animated, useSpring } from 'react-spring';
import { graphql, useStaticQuery } from 'gatsby';

const useStyles = makeStyles<Theme>(({}) => ({
  root: {
    display: 'block',
  },
}));

const logoQuery = graphql`
  query LogoQuery {
    site {
      siteMetadata {
        siteName
      }
    }
  }
`;

interface LogoProps {
  touch?: boolean;
}

const Logo: React.FC<LogoProps> = ({ touch = false }) => {
  const classes = useStyles();
  const { site } = useStaticQuery(logoQuery);
  const { x } = useSpring({ from: { x: 0 }, x: +touch });

  return (
    <animated.div
      className={classes.root}
      style={{
        transform: x
          .interpolate({
            range: [0, 0.33, 0.66, 0.7, 1],
            output: [1, 0.95, 1.05, 0.95, 1],
          })
          .interpolate((x) => `scale(${x})`),
      }}
    >
      <img
        alt={site.siteMetadata.siteName}
        src="/logo.svg"
      />
    </animated.div>
  );
};

export default Logo;
