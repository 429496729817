import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>(({ typography, palette, spacing }) => ({
  '@global': {
    html: {
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      boxSizing: 'border-box',
    },
    '*, *::before, *::after': {
      boxSizing: 'inherit',
    },
    body: {
      ...typography.body1,
      margin: 0,
      backgroundColor: palette.background.default,
      color: palette.common.white,
      height: '100%',
    },
    'html, #___gatsby, #gatsby-focus-wrapper': {
      height: '100%',
    },
    ul: {
      padding: 0,
      margin: 0,

      li: {
        listStyle: 'none',
      }
    },
    a: {
      outline: 'none',
    },
  },
}));

const GlobalStyles: React.FC<{}> = ({ children }) => {
  useStyles();

  return <>{children}</>;
};

export default GlobalStyles;
