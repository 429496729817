import React, { useState, useEffect } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';

import Logo from './Logo';

const useStyles = makeStyles<Theme>(({}) => ({
  root: {
    '&:focus': {
      outline: 'none',
    },
  },
}));

const HomepageLink: React.FC<{}> = () => {
  const classes = useStyles();
  const [touch, toggle] = useState<boolean>(false);

  useEffect(() => {
    toggle(!touch);
  }, []);

  return (
    <GatsbyLink
      className={classes.root}
      onClick={() => toggle(!touch)}
      onFocus={() => toggle(!touch)}
      to="/"
    >
      <Logo touch={touch} />
    </GatsbyLink>
  );
};

export default HomepageLink;
