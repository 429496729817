import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'

import theme from './src/theme';
import Layout from './src/components/Layout';

export const wrapRootElement = ({ element }) => (
  <MuiThemeProvider theme={theme}>{element}</MuiThemeProvider>
);

export const wrapPageElement = ({ element, props }) => (
  <>
    <Layout {...props}>{element}</Layout>
  </>
);

console.log(`%c
   _ |_  _ _  _  _   _  _ _
VV(/_|_)| | |(_|| |.|_)| (_)
                    |
`, 'color: #2979ff');
