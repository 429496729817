import React from 'react';
import { WrapPageElementBrowserArgs } from 'gatsby';
import { Helmet } from 'react-helmet';

import GlobalStyles from './GlobalStyles';
import Header from './Header';
import PageTransition from './PageTransition';

interface LayoutProps {
  children: React.ReactElement<WrapPageElementBrowserArgs>;
  path: string;
  ssr: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, path, ssr }) => (
  <>
    <Helmet>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <GlobalStyles />
    <Header />
    <PageTransition ssr={ssr} path={path}>
      {children}
    </PageTransition>
  </>
);

export default Layout;
