import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import { blue, yellow } from '@material-ui/core/colors';

const fontWeightBold = 800;

let theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 800,
      lg: 1200,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [
      'DM Sans',
      'sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
      'Arial',
    ].join(','),
    fontSize: 17,
    fontWeightBold,
    h1: {
      fontWeight: fontWeightBold,
    },
    h2: {
      fontWeight: fontWeightBold,
    },
    h3: {
      fontWeight: fontWeightBold,
    },
    h4: {
      fontWeight: fontWeightBold,
    },
    h5: {
      fontWeight: fontWeightBold,
    },
    h6: {
      fontWeight: fontWeightBold,
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: yellow,
    secondary: blue,
    background: {
      default: '#000',
    },
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: '1.25rem',
      },
    },
  },
});

theme = responsiveFontSizes(theme, {
  factor: 3,
});

export default theme;
