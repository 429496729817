import React from 'react';
import { useTransition, animated } from 'react-spring';
import { WrapPageElementBrowserArgs } from 'gatsby';

interface PageTransitionProps {
  children: React.ReactElement<WrapPageElementBrowserArgs>;
  path: string;
  ssr: boolean;
}

const PageTransition: React.FC<PageTransitionProps> = ({
  children,
  path,
  ssr,
}) => {
  const transitions = useTransition(path, null, {
    initial: {
      opacity: 1,
    },
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  });

  return (
    <>
      {transitions.map(({ item, props, key }) => {
        const show = ssr || item === children.key;

        return (
          show && (
            <animated.main
              key={key}
              style={{ ...props, width: '100%', height: '100%' }}
            >
              {children}
            </animated.main>
          )
        );
      })}
    </>
  );
};

export default PageTransition;
