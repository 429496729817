import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React, { MouseEventHandler } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';
import { Link as GatsbyLink } from 'gatsby';

import { mergeClasses } from '../utils';

interface LinkProps {
  bold?: boolean;
  className?: string;
  color?: string;
  gutterBottom?: boolean;
  href?: string;
  itemProp?: string;
  rel?: string;

  /** Shows initial white underline. */
  underlined?: boolean;
  target?: string;
  title?: string;
  to?: string;

  /** Disables any underline appearances. */
  noUnderline?: boolean;
  onClick?: MouseEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
}

const useStyles = makeStyles<Theme, Partial<LinkProps>>(
  ({ palette, spacing, transitions }) => ({
    root: {
      alignItems: 'center',
      color: ({ color }) => color || palette.secondary.light,
      display: 'inline-flex',
      fontWeight: ({ bold }) => (bold ? 800 : 400),
      marginBottom: ({ gutterBottom }) => spacing(gutterBottom ? 2 : 0),
      position: 'relative',
      textDecoration: 'none',
      transition: `color ${transitions.duration.shortest}ms`,

      '&::before, &::after': {
        content: ({ noUnderline }: Partial<LinkProps>) =>
          noUnderline ? '' : '""',
        bottom: spacing(-0.25),
        height: 1,
        left: 0,
        position: 'absolute',
        right: 0,
      },

      '&::before': {
        background: ({ underlined, color }: Partial<LinkProps>) =>
          underlined ? color || palette.secondary.light : 'transparent',
      },

      '&::after': {
        transform: 'scale3d(0, 1, 1)',
        background: `linear-gradient(to right, ${palette.primary.main}, ${palette.secondary.main})`,
        transition: `transform ${transitions.duration.shortest}ms`,
      },

      '&.active, &:hover': {
        color: ({ color }) => color || palette.secondary.main,

        '&::after': {
          transform: 'scale3d(1, 1, 1)',
        },
      },
    },
    openInNewIcon: {
      marginLeft: spacing(0.5),
      fontSize: 12,
    },
  })
);

const CustomLink: React.FC<LinkProps> = ({
  bold,
  children,
  className,
  color,
  gutterBottom,
  href,
  noUnderline,
  underlined,
  title,
  to,
  target,
  ...other
}) => {
  const classes = useStyles({
    underlined,
    gutterBottom,
    bold,
    noUnderline,
    color,
  });
  const classNames = mergeClasses(classes.root, className);

  if (href) {
    return (
      <a
        href={href}
        className={classNames}
        title={title}
        target={target}
        {...other}
      >
        {children}
        {target === '_blank' && typeof children === 'string' && (
          <OpenInNewIcon fontSize="inherit" className={classes.openInNewIcon} />
        )}
      </a>
    );
  }

  if (to) {
    return (
      <GatsbyLink
        activeClassName="active"
        className={classNames}
        title={title}
        to={to}
        target={target}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }

  return null;
};

export default CustomLink;
