import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import type { Theme } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/styles/makeStyles';

import Link from './Link';
import HomepageLink from './HomepageLink';

const useStyles = makeStyles<Theme>(({ breakpoints, spacing }) => ({
  root: {
    background: 'transparent',
    boxShadow: 'none',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(2),

    [breakpoints.up('lg')]: {
      padding: spacing(2, 3),
    },
  },
  navList: {
    display: 'flex',
  },
  navListItem: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 2),

    '&:last-child': {
      paddingRight: 0,
    },
  },
}));

const Header: React.FC<{}> = () => {
  const classes = useStyles();

  return (
    <AppBar position="absolute" className={classes.root} color="inherit">
      <Toolbar className={classes.toolbar}>
        <HomepageLink />
        <nav>
          <ul className={classes.navList}>
            <li className={classes.navListItem}>
              <Link bold to="/blog/">
                blog
              </Link>
            </li>
            <li className={classes.navListItem}>
              <Link bold to="/ukraine">
                support Ukraine 🇺🇦
              </Link>
            </li>
          </ul>
        </nav>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
